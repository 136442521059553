import {Component, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {AlertController, MenuController, Platform, ToastController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import { ApiService } from './services/api.service';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public user: any;
  currentPageTitle = 'Dashboard';
  change = async () => {
    const pop = await this.alert.create({
      header: 'Please Confirm',
      subHeader: 'Are you sure?',
      message: 'This will initiate a request to <b>CHANGE or RESET</b> <br/>your <b>PIN CODE</b>. ' +
        '<br/>Do you wish to continue ?',
      cssClass: 'sw-pop',
      backdropDismiss: false,
      mode: 'ios',
      buttons: [
        {text: 'Cancel', role: 'cancel'},
        {
          text: 'Confirm',
          role: 'confirm',
          handler: () => {
            this.router.navigate(['/u/change-pin']).then(() => {

            });
          }
        }
      ]
    });
    await pop.present();
  };

  share = async () => {
    let model: any;
    // eslint-disable-next-line prefer-const
    model = navigator;
    // if (model && model.share) {
      model.share({
        title: 'Dalex SWIFT',
        text: 'Join me on this journey to grow your Money!',
        url: 'https://www.dalexswift.com',
      }).then(() => {}).catch((e) => {});
    // }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  appPages = [
    {
      title: 'Dashboard',
      url: '/u/dashboard',
      icon: 'bar-chart',
    },
    {
      title: 'Deposits',
      url: '/u/deposit',
      icon: 'wallet'
    },
    {
      title: 'Loans',
      url: '/u/loan',
      icon: 'barcode'
    },
    {
      title: 'History',
      url: '/u/history',
      icon: 'list'
    },
    {
      title: 'Calculator',
      url: '/u/calculator',
      icon: 'calculator'
    },
    {
      title: 'Messages',
      url: '/u/messages',
      icon: 'notifications'
    },
    {
      title: 'My Profile',
      url: '/u/profile',
      icon: 'person-circle'
    },
    {
      title: 'Change Pin',
      url: '/u/change-pin',
      icon: 'finger-print'
    },
    {
      title: 'Redeem',
      url: '/u/redeem',
      icon: 'cash'
    },
    {
      title: 'Contact Us',
      url: '/u/contact',
      icon: 'mail-open'
    },
    {
      title: 'Sign out',
      url:'login',
      icon: 'exit'
    }
  ];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private api: ApiService,
    private db: Storage,
    private router: Router,
    private alert: AlertController
  ) {
    this.db.get('user').then(user => {this.user = user; });
  }
  navigateMenu(event: any){
    this.router.navigate([event]);
  }
   sign(){
    return this.api.signout().then(async () =>
      await this.router.navigate(['/login']).then(async () => {
        window.location.reload();
      }));
    }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  async ngOnInit() {
    this.swUpdate.available.subscribe(async () => {
      const toast = await this.toastCtrl.create({
        message: 'Newer version of the app is available. It\'s just a quick refresh away!',
        position: 'bottom',
        buttons: [
          {
            text: 'Reload',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      await toast.present();
      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }
}
