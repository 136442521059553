import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {IonicStorageModule} from '@ionic/storage';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GuardService} from './services/gaurd.service';
import {HttpInterceptorService} from './services/http-interceptor.service';
import {BnNgIdleService} from 'bn-ng-idle';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';
import {ImageCropperModule} from 'ngx-image-cropper';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import {WebcamModule} from 'ngx-webcam';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        WebcamModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          registrationStrategy: 'registerImmediately'
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        GuardService,
        BnNgIdleService,
        NativePageTransitions,
        SocialSharing,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
