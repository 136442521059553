import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardService } from './services/gaurd.service';
import { UserService } from './services/user.service';
import { GuestService } from './services/guest.service';
import { PrevService } from './services/prev.service';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  { path: 'splash', loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashPageModule) },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [GuestService],
    resolve: { user: PrevService },
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'u',
    children: [
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module')
          .then(m => m.DashboardPageModule), data: { preload: false} },
      { path: 'deposit', loadChildren: () => import('./pages/deposit/deposit.module').then(m => m.DepositPageModule) },
      { path: 'history', loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule) },
      { path: 'loan', loadChildren: () => import('./pages/loan/loan.module').then(m => m.LoanPageModule) },
      { path: 'redeem', loadChildren: () => import('./pages/redeem/redeem.module').then(m => m.RedeemPageModule) },
      { path: 'calculator', loadChildren: () => import('./pages/calculator/calculator.module').then(m => m.CalculatorPageModule) },
      { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule) },
      { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule) },
      { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
      { path: 'detail', loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)},
      {path: 'edit-goal',loadChildren: () => import('./pages/edit-goal/edit-goal.module').then( m => m.EditGoalPageModule)},
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [GuestService],
        resolve: { user: PrevService },
      },
      {
        path: 'tell-a-friend',
        loadChildren: () => import('./pages/tell-a-friend/tell-a-friend.module').then(m => m.TellAFriendPageModule)
      },
      { path: 'change-pin', loadChildren: () => import('./pages/change-pin/change-pin.module').then(m => m.ChangePinPageModule) },
      { path: 'e-statement', loadChildren: () => import('./pages/e-statement/e-statement.module').then(m => m.EStatementPageModule) },
      { path: '', redirectTo: '/u/dashboard', pathMatch: 'full' },

    ],
    canActivate: [GuardService],
    resolve: { user: UserService },
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'image-capture',
    loadChildren: () => import('./pages/image-capture/image-capture.module').then( m => m.ImageCapturePageModule)
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
