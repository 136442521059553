import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {Storage} from '@ionic/storage';
import {forkJoin, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly apiKey: string;
    private readonly email: string;

    // constructor
    constructor(private http: HttpClient,
                private sanitizer: DomSanitizer, private db: Storage) {
        this.apiKey = 'c24d21fd-4995-440b-af63-73a5ad0e8d4a';
        this.email = 'linqworth@dalexswift.com';
    }
    url = environment.baseUrl;

    // get token
    public requestJWT(): Promise<any> {
        const data = {emailAddress: this.email, securityKey: this.apiKey};
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/Token', data, {
            headers: head
        }).toPromise();
    }

    // request OTP
    public requestOTP(data: any): Promise<any> {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/SendOTP', data, {
            headers: head
        }).toPromise();
    }

    // create account
    public createAccount(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/Create', data, {
            headers: head
        }).toPromise();
    }

    // accept terms
    public acceptTerms(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/AcceptTerms', data, {
            headers: head
        }).toPromise();
    }

    // login
    public signin(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/Login', data, {
            headers: head
        }).toPromise();
    }

    // profile
    public profile(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/Profile', data, {
            headers: head
        }).toPromise();
    }

    // update profile
    public update(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/Update', data, {
            headers: head
        }).toPromise();
    }

    // change pin
    public password(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Account/UpdatePin', data, {
            headers: head
        }).toPromise();
    }

    // sign-out
    async signout() {
        return await this.db.get('user').then(async (user) =>
          await this.db.clear());
    }

    // deposit
    public deposit(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        let link = this.url + '/Payments/MobileMoney';
        if (data.mode === 'card') {
            link = this.url + '/Payments/Cards';
            data.returnPage = window.location.origin + '/u/dashboard';
        }
        delete data.link;
        delete data.mode;
        return this.http.post(link, data, {
            headers: head
        }).toPromise();
    }

    // Deposit OTP
    public requestDepositOTP(data: any): Promise<any> {
      const head = new HttpHeaders({'Content-Type': 'application/json'});
      return this.http.post(this.url + '/Payments/TransactionOTP', data, {
      headers: head
      }).toPromise();
    }

  // verify Deposit OTP
  public verifyDepositOTP(data: any): Promise<any> {
    const head = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post(this.url + '/Payments/VerifyTransactionOTP', data, {
      headers: head
    }).toPromise();
  }

    // get loan data
    public qLoan(data: any) {
        return forkJoin([
            this.http.get(this.url + '/Loan/Balance?phoneNumber=' + data),
            this.http.get(this.url + '/Loan/Limit?phoneNumber=' + data)
        ]).toPromise();
    }

    // loan
    public loan(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Loan/Request', data, {
            headers: head
        }).toPromise();
    }

    // balance
    public balance(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(this.url + '/Loan/Balance?phoneNumber=' + data, {
            headers: head
        }).toPromise();
    }

    // limit
    public limit(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(this.url + '/Loan/Limit?phoneNumber=' + data, {
            headers: head
        }).toPromise();
    }

    // redeem
    public redeem(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Redemption/Redeem', data, {
            headers: head
        }).toPromise();
    }

    // banks
    public banks(): Observable<any> {
      const head = new HttpHeaders({'Content-Type': 'application/json'});
      return this.http.get<any>('https://bank.linqtest.xyz/bank_request/v1/controller/bank.php'
      );
    }

    // calculate
    public calculate(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Investment/Calculator', data, {
            headers: head
        }).toPromise();
    }

    // has goals
    public hasGoal(phoneNo: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(this.url + '/Investment/HasGoal?phoneNo=' + phoneNo, {
            headers: head
        }).toPromise();
    }

    // set goal
    public setGoal(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Investment/SetGoal', data, {
            headers: head
        }).toPromise();
    }

    // get goal
    public getGoal(phoneNo: string) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(this.url + '/Investment/GetCurrentGoal?phoneNo=' + phoneNo, {
            headers: head
        }).toPromise();
    }

    // update goal
    public updateGoal(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Investment/UpdateGoal', data, {
            headers: head
        }).toPromise();
    }

    // upload photo
    public uploadPhoto(photo: any, photoName: any) {
        const model = new FormData();
        model.append('files', photo, photoName);
        const head = new HttpHeaders({'Content-Type': 'multipart/form-data'});
        return this.http.post(this.url + '/Photo', model).toPromise();
    }
  public uploadGoalPhoto(photo: any) {
    const model = new FormData();
    model.append('files', photo);
    const head = new HttpHeaders({'Content-Type': 'multipart/form-data'});
    return this.http.post(this.url + '/Photo', model).toPromise();
  }

    // mini statement
    public miniStatement(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Investment/MiniStatements', data, {
            headers: head
        }).toPromise();
    }

    public qDash(data: any) {
        return forkJoin([
            this.http.post(this.url + '/Account/AccountBalances', data),
            this.http.post(this.url + '/Investment/MiniStatements', data),
           this.http.get(this.url + '/Investment/GetCurrentGoal?phoneNo=' + data.phoneNumber)
        ]).toPromise();
    }

    // transactions
    public transactions(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Investment/Transactions', data, {
            headers: head
        }).toPromise();
    }

    // push subscription
    public subscribe(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post('https://fcm.dalexswift.com', data, {
            headers: head
        }).toPromise();
    }

    // banners
    public banners() {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get('https://cdn.dalexswift.com/banners', {
            headers: head
        }).toPromise();
    }

    // messages
    public messages(id: string) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(this.url + '/Messages/Messages?id=' + id, {
            headers: head
        }).toPromise();
    }

    // mark as read
    public readMessage(data: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.url + '/Messages/Read', data, {
            headers: head
        }).toPromise();
    }

    // mark as read
    public getEStatement(phoneNum: number, startDt: any, endDt: any) {
        const head = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post<any>('https://dalex-estatement.herokuapp.com/api/v1/generateStatement', {
            phoneNumber: phoneNum,
            startDate: startDt,
            endDate: endDt
        }, {
            headers: head
        }).toPromise();
    }
}
